import React, { Suspense }  from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter as Router} from 'react-router-dom';
import App from "./pages/App/App";
import ReactSwipeEvents from 'react-swipe-events';
import './18n';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Suspense>
      <Router>
        <App />
      </Router>
    </Suspense>
  </React.StrictMode>
)