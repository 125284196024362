import React, { useEffect, lazy } from 'react';
import {Route, Routes} from 'react-router-dom';
import Loader from '../../components/Loader/Loader';
import '../../styles/index.scss';

// import Home from '../../pages/Home/Home'
// import Services from '../../pages/Services/Services'
// import Portfolio from '../../pages/Portfolio/Portfolio'
// import AboutUs from '../../pages/AboutUs/AboutUs'
// import Contact from '../../pages/Contact/Contact'

const Home = lazy(() => import('../Home/Home.jsx'));
// const Home = lazy(() => {
//   return new Promise(resolve => setTimeout(resolve, 3000)).then(
//     () => import('../Home/Home.jsx')
//   );
// });

const Services = lazy(() => import('../Services/Services.jsx'));
const Portfolio = lazy(() => import('../Portfolio/Portfolio.jsx'));
const AboutUs = lazy(() => import('../AboutUs/AboutUs.jsx'));
const Contact = lazy(() => import('../Contact/Contact.jsx'));


const App = () => {
  useEffect(() => {
    const script1 = document.createElement('script');
    const script2 = document.createElement('script');
    const script3 = document.createElement('script');
    script1.src = "https://smtpjs.com/v3/smtp.js";
    script1.async = true;
    script2.src = "https://www.googletagmanager.com/gtag/js?id=G-Z47QFN7XWM";
    script2.async = true;
    script3.text = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', 'G-Z47QFN7XWM');
    `;
    script3.async = true;
    document.head.appendChild(script1);
    document.head.appendChild(script2);
    document.head.appendChild(script3);
    return () => {
      document.head.removeChild(script1);
      document.head.removeChild(script2);
      document.head.removeChild(script3);
    }
  }, []);

  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={(
            <React.Suspense fallback={<Loader/>}>
              <Home/>
            </React.Suspense>
          )}
        />
        <Route
          path="/services"
          element={(
            <React.Suspense fallback={<Loader/>}>
              <Services/>
            </React.Suspense>
          )}
        />
        <Route
          path="/portfolio"
          element={(
            <React.Suspense fallback={<Loader/>}>
              <Portfolio/>
            </React.Suspense>
          )}
        />
        <Route
          path="/about"
          element={(
            <React.Suspense fallback={<Loader/>}>
              <AboutUs/>
            </React.Suspense>
          )}
        />
        <Route
          path="/contact"
          element={(
            <React.Suspense fallback={<Loader/>}>
              <Contact/>
            </React.Suspense>
          )}
        />
      </Routes>
    </div>
  )
}
export default App



